
import { defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import Overview from "@/views/resources/documentation/general/font-awesome/Overview.vue";
import Usage from "@/views/resources/documentation/general/font-awesome/Usage.vue";
import IconColors from "@/views/resources/documentation/general/font-awesome/IconColors.vue";
import IconSizes from "@/views/resources/documentation/general/font-awesome/IconSizes.vue";

export default defineComponent({
  name: "font-awesome",
  components: {
    Overview,
    Usage,
    IconColors,
    IconSizes
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Font Awesome");
    });
  }
});
